import React from "react";
import About from "./about";
import Finance from "./finance";
import Gateway from "./gateway";
import Service from "./service";
import Hero from "./hero";
import Visa from "./visa";
import Pricing from "./pricing";

const Home: React.FC = () => {
    return (
        <div>
            {/* <!-- ======== 1.2. hero section ======== --> */}
            <Hero />
            {/* <!-- ======== 1.3. about section ========  --> */}
            <About />
            {/* <!-- ======== 1.4. finance section ========  --> */}
            <Finance />
            {/* <!-- ======== 1.5. ispsum section ========  --> */}
            {/* <Ipsum /> */}
            {/* <!-- ======== 1.6. gateway section ========  --> */}
            <Gateway />
            {/* <!-- ======== 1.7. services section ========  --> */}
            <Service />
            {/* <!-- ======== 1.8. visa section ========  --> */}
            <Visa />
            {/* <!-- ======== 1.9. pricing section ========  --> */}
            <Pricing />
            {/* <!-- ======== 1.10. professional section ========  --> */}
            {/* <Professional /> */}
            {/* <!-- ======== 1.11. question section ========  --> */}
            {/* <Question /> */}
            {/* <!-- ======== 1.12. news-cards section ========  --> */}
            {/* <News/> */}
        </div>
    );
};

export default Home;
