import React from "react";
import Vesa from "../../../assets/images/index/vesa-back.jpg";
import card from "../../../assets/images/index/Card.png";
import GradientButton from "../../components/button/GradientButton";
import CounterUp from "../../components/Counter/counterUp";
import translator from "../../../Components/Extra/Translation/Translate";
import VisaSlider from "../Stick/visaSlide";

const Visa: React.FC = () => {
    const { translate } = translator();
    return (
        <section className=" flex justify-center items-center mx-8  md:mt-20 lg:!mx-32 z-40">
            <div className="max-w-screen-xl mx-auto w-full leading-normal">
                <div className="w-full" data-aos="zoom-in">
                    <figure>
                        <img
                            src={Vesa}
                            alt="visa-img"
                            className="w-full rounded-3xl"
                        />
                    </figure>
                </div>
                <div
                    className="bg-slate-800 border border-kalyssi-200 relative top-[-30px] md:!top-[-150px] mx-50 md:mx-[100px] px-[20px] md:px-[50px] rounded-xl"
                    data-aos="fade-up"
                >
                    <div className="grid grid-cols-1 md:!grid-cols-2 gap-2 md:pb-4 py-3 md:!py-8">
                        <div className="col-lg-6 col-md-6 text-center md:!text-left  md:!mr-8">
                            <h3 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text font-bold leading-tight tracking-tighter pb-4 md:!text-5xl text-3xl">
                                {translate("VISA", "Title")}{" "}
                            </h3>
                            <p className=" text-[#E4E4E4] text-base">
                                {translate("VISA", "Description")}
                            </p>
                            <div className="text-center md:!text-left py-5">
                                <GradientButton href="/ComingSoon">
                                    {translate("VISA", "Button_Text")}
                                </GradientButton>
                            </div>
                        </div>
                        <div className=" md:!-my-4 pt-6 mb-4 md:!text-left">
                            <div className="overflow-hidden ">
                                <VisaSlider />
                            </div>
                            <div className="flex pt-8 justify-center items-center font-semibold text-5xl space-x-2 md:!justify-start">
                                <h2 className="count ">
                                    {" "}
                                    <CounterUp delay={10} time={4000}>
                                        280
                                    </CounterUp>
                                </h2>
                                <h2>+</h2>
                                <h6 className="flex !text-2xl items-center pl-2 text-white">
                                    Integrations
                                </h6>
                            </div>
                            <p className="flex justify-center md:!justify-start py-2  text-base text-white">
                                {translate("VISA", "Integrations_Description")}
                            </p>
                            <div className="relative flex justify-center md:!justify-start md:!top-[80px]">
                                <img src={card} alt="visa-card" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Visa;
