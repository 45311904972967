import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";

const Condition: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            <section className="flex justify-center items-center mx-8 lg:mx-32 md:pt-32 pt-24 pb-15">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="max-w-screen-xl mx-auto w-full text-center py-8 text-slate-888 ">
                        <h1 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text font-bold text-5xl sm:text-3xl lg:text-6xl leading-tight tracking-tighter">
                            {translate("CONDITION", "h1")}
                        </h1>
                        <div>
                            <div className="bg-[#e5e7eb] text-left  md:px-[140px] px-[20px] py-[80px] mt-[80px] rounded-tl-[120px]  ">
                                <span>
                                    <p>
                                        {translate("CONDITION", "p1_1")}{" "}
                                        <span className="font-bold">
                                            {" "}
                                            KALYSSI{" "}
                                        </span>{" "}
                                        {translate("CONDITION", "p1_2")}{" "}
                                        <span className="font-bold">
                                            {" "}
                                            KALYSSI{" "}
                                        </span>
                                        . <br /> <br />
                                        {translate(
                                            "CONDITION",
                                            "p2",
                                        )} <br /> <br />
                                        {translate("CONDITION", "p3")}
                                        <br /> <br />
                                        {translate(
                                            "CONDITION",
                                            "p4",
                                        )} <br /> <br />
                                        {translate("CONDITION", "p5")}
                                    </p>
                                </span>

                                <div className="flex-wrap  md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A1")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t1")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c1_1")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c1_2")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c1_3")}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A2")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t2")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c2_1")} <br />
                                        <br />
                                        {translate("CONDITION", "c2_2")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c2_3")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c2_4")}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A3")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t3")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c3_1")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c3_2")}
                                    </p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A4")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t4")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c4_1")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c4_2")}
                                    </p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A5")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t5")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "c5")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A6")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t6")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "c6")}</p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A7")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t7")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c7_1")} <br />{" "}
                                        <br />
                                        {translate("CONDITION", "c7_2")}
                                        <br /> <br />
                                        {translate("CONDITION", "c7_3")}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A8")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t8")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c8_1")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c8_2")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c8_3")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c8_4")}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A9")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "t9")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c9_1")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c9_2")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c9_3")}
                                    </p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A10")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t10")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c10_1")} <br />
                                        <br />
                                        {translate("CONDITION", "c10_2")} <br />
                                        <br />
                                        {translate("CONDITION", "c10_3")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c10_4")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c10_5")}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A11")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t11")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "c11_1")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c11_2")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c11_3")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "c11_4")}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A12")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T12")}{" "}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C12")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C12_a")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C12_b")}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A13")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T13")}{" "}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "C13")}</p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A14")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T14")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C14_a")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C14_b")}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A15")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T15")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C15_a")}
                                        <br />
                                        <br />
                                        {translate("CONDITION", "C15_b")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C15_c")}
                                    </p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A16")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T16")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C16_a")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C16_b")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C16_c")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C16_d")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C16_e")}
                                    </p>

                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "T16_a")}
                                    </h5>

                                    <p>
                                        {translate("CONDITION", "C16_f")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C16_g")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C16_h")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C16_i")}
                                    </p>
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "T16_b")}
                                    </h5>

                                    <p>{translate("CONDITION", "C16_j")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A17")}{" "}
                                    </h5>

                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T17")}{" "}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "C17_a")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A18")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T18")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "C18")}</p>
                                </div>
                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A19")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T19")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C19_a")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C19_b")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C19_c")}
                                    </p>
                                </div>
                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A20")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T20")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C20_a")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C20_b")}
                                    </p>
                                </div>
                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A21")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T21")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C21_a")}

                                        <br />
                                        <br />
                                        {translate("CONDITION", "C21_b")}
                                    </p>
                                </div>
                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A22")}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T22")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C22_a")}{" "}
                                        <span className="font-bold">
                                            {" "}
                                            KALYSSI
                                        </span>
                                        .{" "}
                                    </p>
                                </div>
                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A23")}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T23")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "C23")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Condition;
