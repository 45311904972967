export interface ICurrency {
    name: string;
    symbol: string;
    abbrev: string;
    usd_unit_price: number;
}

export default class Currency {
    id?: number;
    name: string;
    symbol: string;
    abbrev: string;
    usd_unit_price: number;
    created_at?: Date;
    updated_at?: Date;

    constructor(
        name: string,
        symbol: string,
        abbrev: string,
        usd_unit_price: number,
    ) {
        this.name = name;
        this.symbol = symbol;
        this.abbrev = abbrev;
        this.usd_unit_price = usd_unit_price;
    }
}
