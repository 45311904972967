import React from "react";
import pricing from "../../../assets/images/index/pricinge.png";
import pricingbg from "../../../assets/images/bg/pricing_bg.png";
import translator from "../../../Components/Extra/Translation/Translate";

const Pricing: React.FC = () => {
    const { translate } = translator();
    return (
        <div className="bg-slate-800 py-12 mb-10">
            <section className="flex justify-center items-center mx-8 lg:!mx-32">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="flex flex-col md:flex-row justify-center items-center gap-5">
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center p-4 h-full items-center w-full md:!w-1/2"
                            style={{
                                backgroundImage: `url(${pricingbg})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <div className="flex justify-center items-center mx-3">
                                <img
                                    src={pricing}
                                    alt="hero_img1"
                                    className=" animate-moveUpDown duration-[3.5s] ease-in-out infinite"
                                />
                            </div>
                        </div>
                        <div
                            data-aos="fade-up"
                            className="flex flex-col justify-start items-center md:!items-start w-full md:!w-2/3 text-center md:!text-left"
                        >
                            <h2 className="text-3xl sm:text-3xl lg:!text-5xl font-semibold leading-tight tracking-tighter">
                                {translate("PRICING", "Title")}
                            </h2>
                            <p className="text-gray-300 my-6 text-base leading-normal">
                                {translate("PRICING", "Description")}
                            </p>
                            {/* <div className="bg-slate-600 border border-kalyssi-200 rounded-xl py-10 my-4 sm:mt-0 md:mt-3 flex flex-col sm:flex-row items-start">
                                <h6 className="rounded-r-3xl bg-100 px-[20px] py-[10px]">
                                    Personal
                                </h6>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full md:px-5 sm:px-0">
                                    <div className="flex items-center ms-2">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="mx-2 text-white bg-kalyssi-200 border-kalyssi-200 border rounded-full md:px-[5px] md:py-[4px]"
                                        />
                                        <p className="text-[14px] mx-4 text-white">
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiset quatit.
                                            Dolorem, quia?
                                        </p>
                                    </div>
                                    <div className="flex items-start mt-4 sm:mt-0 sm:ml-auto">
                                        <h2>$1.54</h2>
                                        <p className="relative text-[#FDBC5C] top-8">
                                            /month
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-slate-600 border border-kalyssi-200 rounded-xl py-10 hover:bg-slate-800 my-8 sm:mt-0 md:mt-3 flex flex-col sm:flex-row items-start">
                                <h6 className="rounded-r-3xl bg-100 px-[20px] py-[10px]">
                                    Business
                                </h6>
                                <div className="flex flex-col sm:flex-row items-center justify-between w-full md:px-5 sm:px-0">
                                    <div className="flex items-center ms-2">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="mx-2 text-white bg-kalyssi-200 border-kalyssi-200 border rounded-full md:px-[5px] md:py-[4px]"
                                        />
                                        <p className="text-[14px] mx-4 text-white">
                                            Lorem ipsum dolor sit amet,
                                            consectetur adipiset quatit.
                                            Dolorem, quia?
                                        </p>
                                    </div>
                                    <div className="flex items-start mt-4 sm:mt-0 sm:ml-auto">
                                        <h2>$4.54</h2>
                                        <p className="relative text-[#FDBC5C] top-8">
                                            /month
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <GradientButton href="#">Get Started</GradientButton> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Pricing;
