import React, { useEffect } from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./timeline.css";
import getTimelineData from "./RoadMapItem";

interface RoadMapItem {
    className?: string;
    contentStyle?: React.CSSProperties;
    contentArrowStyle?: React.CSSProperties;
    dateClassName?: string;
    date?: string;
    iconClassName?: string;
    icon?: React.ReactNode;
    iconStyle?: React.CSSProperties;
    title?: string;
    location?: string;
    description?: string;
}

const Roadmap: React.FC = () => {
    const timelineData = getTimelineData();

    const handleScroll = () => {
        const timeline = document.querySelector(
            ".vertical-timeline",
        ) as HTMLElement;
        if (timeline) {
            const totalHeight =
                document.documentElement.offsetHeight - window.innerHeight;
            const scrolled = window.scrollY;
            const scrollPercent = (scrolled / totalHeight) * 100;
            const redPercent = Math.min(scrollPercent, 100);
            const yellowPercent = redPercent;
            const newLineColor = `linear-gradient(to bottom, #b31c2b ${redPercent}%, #EEA913 ${yellowPercent}%)`;
            timeline.style.setProperty(
                "--vertical-timeline-line-gradient",
                newLineColor,
            );
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div>
            <section className="flex justify-center items-center py-20 mx-8 md:mx-8 lg:mx-32">
                <div className="max-w-screen-xl mx-auto w-full">
                    <h1 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text font-bold text-5xl sm:text-3xl lg:text-6xl leading-tight tracking-tighter py-4 text-center">
                        ROADMAP
                    </h1>
                    <VerticalTimeline
                        lineColor={
                            "var(--vertical-timeline-line-gradient, linear-gradient(to bottom, #b31c2b 0%, #EEA913 0%))"
                        }
                        animate={true}
                        className="vertical-timeline"
                    >
                        {timelineData.map(
                            (item: RoadMapItem, index: number) => (
                                <VerticalTimelineElement
                                    key={index}
                                    className={`vertical-timeline-custom-line ${
                                        item.className || ""
                                    }`}
                                    contentStyle={item.contentStyle || {}}
                                    contentArrowStyle={
                                        item.contentArrowStyle || {}
                                    }
                                    dateClassName={item.dateClassName || ""}
                                    date={item.date || ""}
                                    iconClassName={item.iconClassName || ""}
                                    icon={item.icon}
                                >
                                    {item.title && (
                                        <h3 className="text-lg text-slate-50">
                                            {item.title}
                                        </h3>
                                    )}
                                    {item.location && (
                                        <h6 className="text-slate-50">
                                            {item.location}
                                        </h6>
                                    )}
                                    {item.description &&
                                        item.description
                                            .split("\n")
                                            .map((line, idx) => (
                                                <p
                                                    className="text-slate-50"
                                                    key={idx}
                                                >
                                                    {line}
                                                </p>
                                            ))}
                                </VerticalTimelineElement>
                            ),
                        )}
                    </VerticalTimeline>
                </div>
            </section>
        </div>
    );
};

export default Roadmap;
