import featureGirl from "../../../assets/images/feauter/feature-girl.png";
import featurerect from "../../../assets/images/feauter/feature-rect.png";

const Section1: React.FC = () => {
    return (
        <div>
            <section className="flex justify-center items-center mx-8 lg:mx-32 pt-28 pb-20 md:!pb-0 md:!pt-32  ">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="max-w-screen-xl mx-auto w-full text-center">
                        <h1 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text font-bold text-3xl sm:text-3xl lg:!text-5xl leading-tight tracking-tighter">
                            FEATURES
                        </h1>
                        {/* <p className="mt-0 text-[16px] text-[#E4E4E4] p-6 Fmd:px-[230px]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quasi obcaecati dolorum!
            </p> */}
                    </div>
                    <div className="relative mt-8 w-full hidden">
                        <figure className="relative w-full">
                            <img
                                src={featureGirl}
                                alt="Feature Girl"
                                className="w-full rounded-[25px] object-cover z-20 relative"
                            />
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30">
                                <div className="featur-video inline-block w-30 px-6">
                                    <a
                                        className="relative z-10 block w-16 h-27 rounded-full border border-white overflow-hidden"
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            padding: "18px 20px 18px 28px",
                                        }}
                                        href="#"
                                    >
                                        <span className="absolute inset-0 flex items-center justify-center">
                                            <span className="w-60 h-60 rounded-full bg-100 animate-pulse"></span>
                                        </span>
                                        {/* <span className="text-white font-bold text-[25px] flex items-center justify-center w-full h-full rounded-full">
                                            <FontAwesomeIcon icon={faPlay} />
                                        </span> */}
                                    </a>
                                </div>
                            </div>
                        </figure>
                        <figure className="absolute bottom-0 right-0 z-10 transform translate-x-[30px] translate-y-[43px]">
                            <img
                                src={featurerect}
                                alt="Feature Rect"
                                className="w-full max-w-[150px] md:max-w-full"
                            />
                        </figure>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Section1;
