import React from "react";
import gateway from "../../../assets/images/index/gateway-1.png";
import bggateway from "../../../assets/images/bg/gateway.png";
import gateicon1 from "../../../assets/images/icon/gate-icon1.png";
import gateicon2 from "../../../assets/images/icon/gate-icon2.png";
import gateicon3 from "../../../assets/images/icon/gate-icon3.png";
import translator from "../../../Components/Extra/Translation/Translate";
import GradientButton from "../../components/button/GradientButton";

const Gateway: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.6. gateway section ========  --> */}
            <section className="flex justify-center items-center my-32 md:my-0 mx-6 lg:!mx-32">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="flex flex-col md:flex-row justify-center items-center gap-5 sm:gap-4 md:gap-0 text-center md:text-left">
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full  sm:mt-5 bg-cover "
                            style={{
                                backgroundImage: `url(${bggateway})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <div className="flex justify-center items-center mx-2">
                                <img
                                    src={gateway}
                                    alt="hero_img1"
                                    className="px-8 animate-moveUpDown duration-[3.5s] ease-in-out infinite opacity-9"
                                />
                            </div>
                        </div>
                        <div
                            data-aos="fade-up"
                            className="flex flex-col md:!text-left justify-start items-center md:items-start w-full md:w-1/2 md:pl-8 align-top"
                        >
                            <h2 className="text-3xl sm:text-3xl lg:!text-5xl font-semibold  leading-tight tracking-tighter">
                                {translate("GATEWAY", "Title")}
                            </h2>
                            <p className="text-gray-300 my-6 text-base leading-normal font-poppins">
                                {translate("GATEWAY", "Description")}
                            </p>
                            <div className="mt-4 sm:mt-0 flex flex-col sm:flex-col md:!flex-row items-center">
                                <figure className="rounded-full  bg-100 px-[21px] py-[15px]">
                                    <img
                                        src={gateicon1}
                                        className="md:!w-[35px] w-[20px] text-base"
                                        alt="gate-img1"
                                    />
                                </figure>
                                <div className="ms-3 text-center md:!text-left">
                                    <h5 className="pb-2 text-xl font-semibold text-[#E4E4E4]">
                                        {translate(
                                            "GATEWAY",
                                            "Create_Account_Title",
                                        )}
                                    </h5>
                                    <p className="text-base text-white">
                                        {translate(
                                            "GATEWAY",
                                            "Create_Account_Text",
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div className="mt-8 flex flex-col sm:flex-col md:flex-row items-center">
                                <figure className="rounded-full bg-100 p-[15px]">
                                    <img
                                        src={gateicon2}
                                        className="md:!w-[70px] w-[30px]"
                                        alt="gate-img2"
                                    />
                                </figure>
                                <div className="ms-3 text-center md:!text-left">
                                    <h5 className="pb-2 text-xl font-semibold text-[#E4E4E4]">
                                        {translate(
                                            "GATEWAY",
                                            "User_Configuration_Title",
                                        )}
                                    </h5>
                                    <p className="text-base text-white">
                                        {translate(
                                            "GATEWAY",
                                            "User_Configuration_Text",
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div className="mt-8 mb-5 flex flex-col sm:flex-col md:flex-row items-center">
                                <figure className="rounded-full  bg-100  px-[21px] py-[15px]">
                                    <img
                                        src={gateicon3}
                                        className="md:!w-[50px] w-[20px]"
                                        alt="gate-img3"
                                    />
                                </figure>
                                <div className="ms-3 text-center md:!text-left">
                                    <h5 className="pb-2 text-xl font-semibold text-[#E4E4E4]">
                                        {translate(
                                            "GATEWAY",
                                            "Full_Access_Title",
                                        )}
                                    </h5>
                                    <p className="text-base text-white">
                                        {translate(
                                            "GATEWAY",
                                            "Full_Access_Text",
                                        )}
                                    </p>
                                </div>
                            </div>
                            <GradientButton
                                className="text-base"
                                href="/ComingSoon"
                            >
                                {translate("GATEWAY", "Button")}
                            </GradientButton>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Gateway;
