import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import useAuthentication from "../Services/Authentication/useAuthentication";
import Transaction, {
    OUT_TRANSACTIONS,
    WalletHistoryStatus,
    WalletHistoryType,
} from "../Models/Transaction";
import UtilsService, { formatAmount } from "../Services/UtilsService";

import { useReactToPrint } from "react-to-print";
interface ContextProps {
    transactions: any[];
    setTransaction: (transactions: any) => any;
    print: any;
    setDates: (dates: any) => any;
}
const TransactionItem = ({ transaction }: { transaction: Transaction }) => {
    const type = transaction.type;
    const includedValues = OUT_TRANSACTIONS;
    const operation = `${
        !includedValues.includes(type) ? "+ " : "- "
    } ${formatAmount(
        transaction.amount,
        transaction.user_wallet.type === "crypto" ? 8 : 2,
    )}`;
    let debit = "";
    let credit = "";
    if (includedValues.includes(type)) {
        debit = `${UtilsService.formatAmount(transaction.amount, 8)}`;
    } else {
        credit = `${UtilsService.formatAmount(transaction.amount, 8)}`;
    }
    return (
        <tr key={transaction.id}>
            <td className="text-center">{transaction.date_fr}</td>
            <td className="text-center">{transaction.description}</td>
            <td className="text-center">{debit}</td>
            <td className="text-center">{credit}</td>
        </tr>
    );
};
const ExportPdfContext = createContext<ContextProps>({
    transactions: [],
    setTransaction: (transactions: any) => [],
    print: () => {
        console.log("");
    },
    setDates: (dates: any) => [],
});

export default function ExportPdfProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const componentRef = useRef(null);
    const [transactions, setTransaction] = useState<any[]>([]);
    const [dates, setDates] = useState<any>({
        endDate: undefined,
        startDate: undefined,
    });
    const { user } = useAuthentication();
    const { debit, credit } = useMemo(() => {
        let totalCredit = 0;
        let totalDebit = 0;
        for (const trans of transactions) {
            const type = trans.type;
            const includedValues = OUT_TRANSACTIONS;
            if (trans.status !== WalletHistoryStatus.COMPLETED) continue;
            if (includedValues.includes(type)) {
                totalDebit = totalDebit + trans.usd_price;
            } else {
                totalCredit = totalCredit + trans.usd_price;
            }
        }

        return {
            debit: totalDebit,
            credit: totalCredit,
        };
    }, [transactions.length, dates.endDate, dates.startDate]);

    const handlePrint2 = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: false,
    });

    useEffect(() => {
        const linkElement = document.createElement("link");
        linkElement.rel = "stylesheet";
        // linkElement.href = UtilsService.getPulicImage("/dist/table.css");
        linkElement.href = UtilsService.getPulicImage("/dist/table.css");
        if (componentRef.current)
            (componentRef.current as any).appendChild(linkElement);
    }, []);

    return (
        <ExportPdfContext.Provider
            value={{
                transactions,
                setTransaction,
                print: handlePrint2,
                setDates,
            }}
        >
            {children}

            <div id="id-printable-element" className="container-fluid hidden">
                <div style={{ padding: "10px" }} ref={componentRef}>
                    <div
                        style={{
                            display: "flex",
                            gap: "5%",
                            marginBottom: "30px",
                        }}
                    >
                        <div style={{ width: "100%" }}>
                            <img
                                style={{ maxWidth: "220px", maxHeight: "60px" }}
                                src={UtilsService.getPulicImage(
                                    "/dist/logo/Kalyssi-2.png",
                                )}
                                alt=""
                            />
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "0px",
                                }}
                            >
                                <div>Kaloum Almamya, </div>
                                <div>Av de la république 5em bld</div>
                                <div>Conakry</div>
                            </div>
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "0px",
                                }}
                            >
                                <div>N° de compte {user?.uid}</div>
                                <div>Monnaie du compte: Dollars</div>
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            <div
                                style={{
                                    marginLeft: "50px",
                                    marginTop: "20px",
                                }}
                            >
                                <div>
                                    <div
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: 1000,
                                        }}
                                    >
                                        <b>RELEVÉ DE COMPTE</b>
                                    </div>
                                    {dates.startDate && dates.endDate ? (
                                        <div>
                                            du mois{" "}
                                            {UtilsService.getBeautifulFormatedDateFr(
                                                dates.startDate,
                                            )}{" "}
                                            au{" "}
                                            {UtilsService.getBeautifulFormatedDateFr(
                                                dates.endDate,
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            du mois{" "}
                                            {UtilsService.getBeautifulFormatedDateFr(
                                                new Date(),
                                            )}
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            textTransform: "capitalize",
                                            marginTop: "10px",
                                        }}
                                    >
                                        {user?.first_name +
                                            " " +
                                            user?.last_name}
                                    </div>
                                </div>

                                <div style={{ marginTop: "30px" }}>
                                    <div>
                                        Total des credits :{" "}
                                        <b>
                                            {UtilsService.formatAmount(
                                                credit,
                                                2,
                                            )}{" "}
                                            $
                                        </b>
                                    </div>
                                    <div>
                                        Total des debits :{" "}
                                        <b>
                                            {UtilsService.formatAmount(
                                                debit,
                                                2,
                                            )}{" "}
                                            $
                                        </b>
                                    </div>
                                    <div>
                                        Solde de cloture :{" "}
                                        <b>
                                            {UtilsService.formatAmount(
                                                credit - debit,
                                                2,
                                            )}{" "}
                                            $
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <table
                            id="id-transaction"
                            className="table table-bordered table-striped"
                            style={{ width: "100%", height: "auto" }}
                        >
                            <thead
                                className="thead-dark"
                                style={{ borderBottom: "1px solid black" }}
                            >
                                <tr style={{ fontSize: "20px" }}>
                                    <th scope="col" className="text-center">
                                        DATE
                                    </th>
                                    <th scope="col" className="text-center">
                                        OPÉRATIONS
                                    </th>
                                    <th scope="col" className="text-center">
                                        DÉBIT
                                    </th>
                                    <th scope="col" className="text-center">
                                        CRÉDIT
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.length > 0 ? (
                                    transactions.map(
                                        (
                                            transaction: Transaction,
                                            index: number,
                                        ) =>
                                            transaction.status ===
                                                WalletHistoryStatus.COMPLETED && (
                                                <TransactionItem
                                                    transaction={transaction}
                                                    key={index}
                                                />
                                            ),
                                    )
                                ) : (
                                    <tr
                                        style={{
                                            border: "1px solid",
                                        }}
                                    >
                                        <td></td>
                                        <td
                                            style={{
                                                textAlign: "center",
                                                fontWeight: "1000",
                                                fontSize: "21px",
                                            }}
                                        >
                                            Aucune opération durant cette
                                            période
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ExportPdfContext.Provider>
    );
}

export const usePdfExport = () => useContext(ExportPdfContext);
