import React from "react";
import featureMob from "../../../assets/images/feauter/mobile.png";
import coreFeatureBg from "../../../assets/images/bg/coreFeature.png";
import translator from "../../../Components/Extra/Translation/Translate";

const CoreFeatures: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            <section className="flex justify-center items-center mx-8 lg:mx-32 md:py-4 mb-28 relative">
                <div className="max-w-screen-xl mx-auto w-full leading-normal">
                    <h2 className="text-3xl sm:text-3xl lg:!text-5xl font-semibold text-center leading-tight tracking-tighter md:pb-12">
                        {translate("FEATURE", "H1")}
                    </h2>
                    <div className="flex flex-col text-base md:flex-row gap-4 md:gap-5 w-full ">
                        <img
                            src={coreFeatureBg}
                            alt="Core Feature Background"
                            className="hidden md:!block absolute inset-0 object-contain w-full h-[112%] -z-10"
                        />
                        <div
                            className="flex flex-col gap-6 w-full md:w-1/3 text-center md:!text-right"
                            data-aos="fade-up"
                        >
                            <div className="bg-kalyssi-800 border border-kalyssi-200 rounded-xl px-4 py-4 ">
                                <h5 className="py-5 text-xl font-semibold text-[#E4E4E4]">
                                    {translate("FEATURE", "T1")}
                                </h5>
                                <p className="text-white">
                                    {translate("FEATURE", "C1")}
                                </p>
                            </div>
                            <div className="bg-kalyssi-800 border border-kalyssi-200 rounded-xl px-4 py-4 ">
                                <h5 className="py-5 text-xl font-semibold text-[#E4E4E4]">
                                    {translate("FEATURE", "T2")}
                                </h5>
                                <p className="text-white">
                                    {translate("FEATURE", "C2")}
                                </p>
                            </div>
                            <div className="bg-kalyssi-800 border border-kalyssi-200 rounded-xl px-4 py-4 ">
                                <h5 className="py-5 text-xl font-semibold text-[#E4E4E4]">
                                    {translate("FEATURE", "T3")}
                                </h5>
                                <p className="text-white">
                                    {translate("FEATURE", "C3")}
                                </p>
                            </div>
                        </div>
                        <div
                            className="flex justify-center items-center w-full md:w-1/3"
                            data-aos="zoom-in"
                        >
                            <figure>
                                <img
                                    src={featureMob}
                                    alt="Feature Image"
                                    className="w-full"
                                />
                            </figure>
                        </div>
                        <div
                            className="flex flex-col gap-6 w-full md:w-1/3 md:!text-left text-center"
                            data-aos="fade-down"
                        >
                            <div className="bg-kalyssi-800 border border-kalyssi-200 rounded-xl px-4 py-4 ">
                                <h5 className="py-5 text-xl font-semibold text-[#E4E4E4]">
                                    {translate("FEATURE", "T4")}
                                </h5>
                                <p className="text-white">
                                    {translate("FEATURE", "C4")}
                                </p>
                            </div>
                            <div className="bg-kalyssi-800 border border-kalyssi-200 rounded-xl px-4 py-4 ">
                                <h5 className="py-5 text-xl font-semibold text-[#E4E4E4]">
                                    {translate("FEATURE", "T5")}
                                </h5>
                                <p className="text-white">
                                    {translate("FEATURE", "C5")}
                                </p>
                            </div>
                            <div className="bg-kalyssi-800 border border-kalyssi-200 rounded-xl px-4 py-4 ">
                                <h5 className="py-5 text-xl font-semibold text-[#E4E4E4]">
                                    {translate("FEATURE", "T6")}
                                </h5>
                                <p className="text-white">
                                    {translate("FEATURE", "C6")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CoreFeatures;
