import React from "react";

import Hero from "./hero";
import Finance from "../Home/finance";
import Visa from "./visa";
import Gateway from "../Home/gateway";
import World from "./world";

const About: React.FC = () => {
    return (
        <div>
            <Hero />
            <Finance />
            {/* <Ipsum /> */}
            <Visa />
            <Gateway />
            <World />
            {/* <Behind /> */}
            {/* <Professional /> */}
        </div>
    );
};

export default About;
