import React from "react";

import Hero from "./hero";
import Form from "./form";

const Contact: React.FC = () => {
    return (
        <div>
            <Hero />
            <Form />
        </div>
    );
};

export default Contact;
