import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import vis1 from "../../../assets/images/icon/vis-1.png";
import vis2 from "../../../assets/images/icon/ves-2.png";
import vis3 from "../../../assets/images/icon/ves-3.png";
import vis4 from "../../../assets/images/icon/ves-4.png";
import vis5 from "../../../assets/images/icon/ves-5.png";

const VisaSlider: React.FC = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0, // Ajustez la vitesse de défilement en millisecondes (0 pour désactiver la pause)
        speed: 9000, // Ajustez la vitesse de transition en millisecondes
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false, // Désactiver la navigation avec les flèches
        swipe: false, // Désactiver la navigation par balayage
        draggable: false, // Désactiver la navigation par glissement
        touchMove: false, // Désactiver la navigation tactile
        cssEase: "linear", // Utiliser une animation linéaire pour un défilement fluide
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return (
        <Slider {...settings}>
            <div>
                <img src={vis1} alt="qr-code" />
            </div>
            <div>
                <img src={vis2} alt="qr-code" />
            </div>
            <div>
                <img src={vis3} alt="qr-code" />
            </div>
            <div>
                <img src={vis4} alt="qr-code" />
            </div>
            <div>
                <img src={vis5} alt="qr-code" />
            </div>
        </Slider>
    );
};

export default VisaSlider;
