import React from "react";
import ladymobile from "../../../assets/images/index/lady-mobile.png";
import servicebghero from "../../../assets/images/bg/services-bg.png";
import translator from "../../../Components/Extra/Translation/Translate";
import GradientButton from "../../components/button/GradientButton";

const Hero: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.2. hero section ======== --> */}
            <section className="flex justify-center items-center mx-8 lg:mx-32 md:pt-32 pt-32 mb-32 pb-15">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="max-w-screen-xl mx-auto w-full text-center md:pb-20 pb-12 ">
                        <h1 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text text-3xl sm:text-3xl lg:!text-5xl font-semibold  leading-tight tracking-tighter">
                            {translate("AboutHero", "title_h1")}
                        </h1>
                        {/* <p className="mt-0 text-[16px] text-[#E4E4E4] p-6 md:px-[230px]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
                            laborum quibusdam consequatur.</p> */}
                    </div>
                    <div className="flex flex-col md:flex-row justify-center items-center gap-5 sm:gap-4 md:gap-0 text-center md:!text-left">
                        <div
                            data-aos="fade-up"
                            className="flex flex-col justify-center items-center md:!items-start w-full md:w-1/2"
                        >
                            <h2 className="text-3xl sm:text-3xl lg:!text-5xl font-semibold  leading-tight tracking-tighter">
                                {translate("AboutHero", "title_h2")}
                            </h2>
                            <p className="text-gray-300 my-6 text-base  leading-normal ">
                                {translate("AboutHero", "p1")}
                            </p>
                            <p className="text-gray-300 my-6 text-base  leading-normal ">
                                {translate("AboutHero", "p2")}
                            </p>
                            <div className="flex justify-center text-base md:pt-5 items-center gap-4 md:gap-3 sm:gap-2">
                                <GradientButton href="/ComingSoon">
                                    {translate("AboutHero", "textButton")}
                                </GradientButton>
                            </div>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative w-full md:w-1/2 sm:mt-5 md:-top-[70px]"
                        >
                            {/* <!-- Background Container with specified size and background size adjustment --> */}
                            <div
                                className="absolute inset-0 flex justify-center items-center bg-cover bg-center md:w-[110%] w-[100%]"
                                style={{
                                    backgroundImage: `url(${servicebghero})`,
                                    height: "130%",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                }}
                            ></div>

                            {/* <!-- Image Container --> */}
                            <div className="relative flex justify-center items-center h-full py-1">
                                <img
                                    src={ladymobile}
                                    alt="hero_img1"
                                    className="relative w-1/2 sm:w-1/2 md:w-2/3 lg:w-1/2 mx-auto right-8 top-20"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Hero;
