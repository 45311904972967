import { NavLink } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import UtilsService from "../../Services/UtilsService";
import LanguageSwitcher from "../Account/Language/Language";
import ThemeSwitcher from "../Account/ThemeSwticher/ThemeSwitcher";
const Head = () => {
    const { isAuthenticated } = useAuthentication();
    const { translate } = translator();

    return (
        <div className="top-bar mdg-nav-bar mdg-theme-nav">
            <nav
                aria-label="breadcrumb"
                className="-intro-x mr-auto hidden sm:flex"
            >
                <div className="p-4 sm:w-64">
                    <NavLink
                        to="/"
                        className="active mdg-logo mx-auto !mt-[-10px] flex h-10 w-full justify-center text-orange-500 hover:text-yellow-500"
                        aria-current="page"
                    >
                        <img
                            src={UtilsService.getPulicImage(
                                "/dist/logo/Kalyssi-2.png",
                            )}
                            className="h-[50px] w-auto"
                            alt="Kalypay Logo"
                        />
                    </NavLink>
                </div>
            </nav>

            {isAuthenticated && (
                <div className="intro-x relative mr-3 sm:mr-6">
                    <NavLink
                        to="/"
                        className="btn btn-default-soft w-30 p-2 text-white border-gray-500"
                    >
                        {translate("Account", "Account")}
                    </NavLink>
                </div>
            )}
            <div className="intro-x relative mr-3 sm:mr-6 language">
                <LanguageSwitcher />
            </div>
            <div className="flex items-center justify-end">
                <div className="intro-x dropdown mr-5 flex h-7 w-5 items-center">
                    <ThemeSwitcher />
                </div>
            </div>
        </div>
    );
};

export default Head;
