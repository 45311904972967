import React from "react";

import graph from "../../../assets/images/icon/graphe.png";
import dollar from "../../../assets/images/icon/doller.png";
import arrow from "../../../assets/images/icon/arow.png";
import translator from "../../../Components/Extra/Translation/Translate";
const Finance: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.4. finance section ========  --> */}
            <section className="flex justify-center items-center mx-8 my-[100px] lg!:mx-32">
                <div className="max-w-screen-xl mx-auto w-full text-center space-y-5">
                    <h2 className="text-3xl sm:text-3xl lg:!text-5xl font-semibold  leading-tight tracking-tighter">
                        {translate("FINANCE", "Title")}
                    </h2>
                    <p className="mt-0 text-base text-[#E4E4E4] p-0 md:!px-36">
                        {translate("FINANCE", "p")}
                    </p>
                    <div className="flex flex-col md:!flex-row gap-4 md:gap-2">
                        <div className="md:w-1/3 flex justify-center md:pr-4">
                            <div
                                className="p-10 bg-kalyssi-800 rounded-2xl"
                                data-aos="flip-up"
                            >
                                <figure className="flex justify-center p-5 w-[118px] h-[100px] m-auto rounded-[15px] custom-gradient">
                                    <img src={graph} alt="graph" />
                                </figure>
                                <h4 className="text-center p-[20px] text-2xl font-semibold text-[#E4E4E4]">
                                    {translate("FINANCE", "Market_Share_Title")}{" "}
                                </h4>
                                <p className="text-[#E4E4E4] text-base p-f-s text-center">
                                    {translate("FINANCE", "Market_Share_Text")}
                                </p>
                            </div>
                        </div>
                        <div className="md:w-1/3 flex justify-center md:pr-4">
                            <div
                                className="p-10 bg-kalyssi-800  rounded-2xl"
                                data-aos="flip-up"
                            >
                                <figure className="flex justify-center p-5 w-[118px] h-[100px] m-auto rounded-[15px] custom-gradient">
                                    <img src={dollar} alt="dollar" />
                                </figure>
                                <h4 className="text-center text-2xl font-semibold p-[20px] text-[#E4E4E4]">
                                    {translate("FINANCE", "Easy_Payment_Title")}
                                </h4>
                                <p className="text-[#E4E4E4] text-base text-center">
                                    {translate("FINANCE", "Easy_Payment_Text")}
                                </p>
                            </div>
                        </div>
                        <div className="md:w-1/3 flex justify-center">
                            <div
                                className="p-10 bg-kalyssi-800  rounded-2xl"
                                data-aos="flip-up"
                            >
                                <figure className="flex justify-center p-5 w-[118px] h-[100px] m-auto rounded-[15px] custom-gradient">
                                    <img src={arrow} alt="arrow" />
                                </figure>
                                <h4 className="text-center p-[20px] text-2xl font-semibold text-[#E4E4E4]">
                                    {translate(
                                        "FINANCE",
                                        "Great_Services_Title",
                                    )}
                                </h4>
                                <p className="text-[#E4E4E4] text-base text-center">
                                    {translate(
                                        "FINANCE",
                                        "Great_Services_Text",
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Finance;
