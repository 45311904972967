import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";

const Confidentiality: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            <section className="flex justify-center items-center mx-8 lg:mx-32 md:pt-32 pt-24 pb-15">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="max-w-screen-xl mx-auto w-full text-center py-8 text-slate-888 ">
                        <h1 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text font-bold text-5xl sm:text-3xl lg:text-6xl leading-tight tracking-tighter">
                            {translate("confidentiality", "title_h1")}
                        </h1>
                        <div>
                            <div className="bg-[#e5e7eb] text-left  md:px-[140px] px-[20px] py-[80px] mt-[70px] rounded-tl-[120px]  ">
                                <div className="md:flex flex-wrap items-center mt-[10px]">
                                    <h5 className="text-[14px]  py-[13px]">
                                        {translate(
                                            "confidentiality",
                                            "article1",
                                        )}
                                    </h5>
                                    <p className="ml-1">
                                        {translate(
                                            "confidentiality",
                                            "p1_article1",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate(
                                            "confidentiality",
                                            "p2_article1",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p3_article1",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p4_article1",
                                        )}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate(
                                            "confidentiality",
                                            "article2",
                                        )}
                                    </h5>
                                    <p className="ml-1">
                                        {translate(
                                            "confidentiality",
                                            "p1_article2",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate(
                                            "confidentiality",
                                            "p2_article2",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p3_article2",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p4_article2",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p5_article2",
                                        )}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate(
                                            "confidentiality",
                                            "article3",
                                        )}
                                    </h5>
                                    <p className="ml-1">
                                        {translate(
                                            "confidentiality",
                                            "p1_article3",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate(
                                            "confidentiality",
                                            "p2_article3",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p3_article3",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p4_article3",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p5_article3",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p6_article3",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p7_article3",
                                        )}
                                    </p>
                                </div>

                                <div className="flex-wrap items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate(
                                            "confidentiality",
                                            "article4",
                                        )}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate(
                                            "confidentiality",
                                            "p1_article4",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate(
                                            "confidentiality",
                                            "p2_article4",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p3_article4",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p4_article4",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p5_article4",
                                        )}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate(
                                            "confidentiality",
                                            "article5",
                                        )}
                                    </h5>
                                    <p className="ml-1">
                                        {translate(
                                            "confidentiality",
                                            "p1_article5",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate(
                                            "confidentiality",
                                            "p2_article5",
                                        )}
                                    </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate(
                                            "confidentiality",
                                            "article6",
                                        )}
                                    </h5>
                                    <p className="ml-1">
                                        {translate(
                                            "confidentiality",
                                            "p1_article6",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate(
                                            "confidentiality",
                                            "p2_article6",
                                        )}
                                    </p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate(
                                            "confidentiality",
                                            "article7",
                                        )}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate(
                                            "confidentiality",
                                            "p1_article7",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div className="flex items-center space-x-2 ">
                                    <p className="flex-wrap md:flex space-x-2 text-left ">
                                        {" "}
                                        <span className="font-bold">
                                            {" "}
                                            {translate(
                                                "confidentiality",
                                                "type1_article7",
                                            )}
                                        </span>{" "}
                                        {translate(
                                            "confidentiality",
                                            "description1_article7",
                                        )}
                                    </p>
                                </div>

                                <div className="flex items-center space-x-2 mt-[20px] ">
                                    <p className="flex-wrap md:flex space-x-2 text-left ">
                                        {" "}
                                        <span className="font-bold">
                                            {" "}
                                            {translate(
                                                "confidentiality",
                                                "type2_article7",
                                            )}
                                        </span>{" "}
                                        {translate(
                                            "confidentiality",
                                            "description2_article7",
                                        )}
                                    </p>
                                </div>

                                <div className="flex items-center space-x-2 mt-[20px] ">
                                    <p className="flex-wrap md:flex space-x-2 text-left ">
                                        {" "}
                                        <span className="font-bold">
                                            {" "}
                                            {translate(
                                                "confidentiality",
                                                "type3_article7",
                                            )}
                                        </span>{" "}
                                        {translate(
                                            "confidentiality",
                                            "description3_1_article7",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "description3_2_article7",
                                        )}
                                    </p>
                                </div>
                                <div className="flex items-center space-x-2 mt-[20px] ">
                                    <p className="flex-wrap md:flex space-x-2 text-left ">
                                        {" "}
                                        <span className="font-bold">
                                            {" "}
                                            {translate(
                                                "confidentiality",
                                                "type4_article7",
                                            )}
                                        </span>{" "}
                                        {translate(
                                            "confidentiality",
                                            "description4_article7",
                                        )}
                                    </p>
                                </div>
                                <div className="flex items-center space-x-2 mt-[20px] ">
                                    <p className="flex-wrap md:flex space-x-2 text-left ">
                                        {" "}
                                        <span className="font-bold">
                                            {" "}
                                            {translate(
                                                "confidentiality",
                                                "type5_article7",
                                            )}{" "}
                                        </span>{" "}
                                        {translate(
                                            "confidentiality",
                                            "description5_article7",
                                        )}
                                    </p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate(
                                            "confidentiality",
                                            "article8",
                                        )}
                                    </h5>
                                    <p className="ml-1">
                                        {translate(
                                            "confidentiality",
                                            "p1_article8",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate(
                                            "confidentiality",
                                            "p2_article8",
                                        )}
                                        <br />
                                        <br />
                                        {translate(
                                            "confidentiality",
                                            "p3_article8",
                                        )}
                                    </p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("confidentiality", "links")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate(
                                            "confidentiality",
                                            "p1_links",
                                        )}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div>
                                    <p>
                                        {translate(
                                            "confidentiality",
                                            "p2_links",
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Confidentiality;
