import Service from "../Home/service";
import Visa from "../Home/visa";
import Section1 from "./Section1";
import CoreFeatures from "./Section3";

const Feature: React.FC = () => {
    return (
        <div>
            <Section1 />
            <Service />
            <CoreFeatures />
            <Visa />
        </div>
    );
};

export default Feature;
