import React, { forwardRef, Ref } from "react";
import { NavLink } from "react-router-dom";
import "./GradientButtonCss.css";

interface GradientButtonProps {
    href: string;
    className?: string;
    children: React.ReactNode;
    download?: string;
    onClick?: (
        e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    ) => void;
}

const GradientButton = forwardRef<
    HTMLAnchorElement | HTMLButtonElement,
    GradientButtonProps
>(
    (
        { href, className, children, download, onClick },
        ref: Ref<HTMLAnchorElement | HTMLButtonElement>,
    ) => {
        const handleClick = (
            e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
        ) => {
            if (onClick) {
                onClick(e);
            }

            if (download && href) {
                e.preventDefault();
                const link = document.createElement("a");
                link.href = href;
                link.download = download;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        };

        const commonClasses = `btn-hover1 text-nowrap relative inline-block overflow-hidden rounded-full border-[1.5px] border-[#DB9B1E] text-white z-[1] px-9 py-3.5 transition-all duration-300 ease-linear text-center ${className}`;

        if (href) {
            return (
                <NavLink
                    to={href}
                    className={`${commonClasses} ${
                        download ? "cursor-pointer" : ""
                    }`}
                    onClick={handleClick}
                    ref={ref as Ref<HTMLAnchorElement>}
                >
                    {children}
                    <span className="absolute inset-0 w-0 h-full transition-all duration-300 ease-in-out bg-gradient-to-r from-[#2589AC] to-[#DB9B1E] z-[-1] rounded-full hover:w-full"></span>
                </NavLink>
            );
        }

        return (
            <button
                type="button"
                className={commonClasses}
                onClick={handleClick}
                ref={ref as Ref<HTMLButtonElement>}
            >
                {children}
            </button>
        );
    },
);

export default GradientButton;
