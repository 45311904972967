import React from "react";
import GradientButton from "../../components/button/GradientButton";
import { Clock, House, Mail, Phone } from "lucide-react";

const Form: React.FC = () => {
    return (
        <div>
            {/* <!-- ======== 1.7. services section ========  --> */}
            <section className="flex justify-center items-center mx-8 lg:mx-32 md:mb-28 mb-20">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="flex flex-col md:!flex-row justify-center md:!items-stretch items-center gap-5 md:!gap-0 text-center md:space-x-12  md:text-left">
                        <div
                            data-aos="fade-down"
                            className="flex flex-col justify-center items-center md:items-start w-full md:w-1/2 "
                        >
                            <div className="flex flex-col justify-center items-start md:items-start rounded-2xl bgcontact h-full px-[30px] py-[50px]">
                                <h5 className="text-white text-[18px]">
                                    HOW CAN WE HELP?
                                </h5>
                                <p className="text-white text-left my-6 font-light leading-normal font-poppins">
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. assumenda eligendi debitis
                                    sunt sequi tempora corporis, sed id animi
                                    eveniet.
                                </p>
                                <ul className="flex space-y-4 flex-col gap-3 text-white text-[18px]">
                                    <li className="flex items-center space-x-5">
                                        <a href="#" className="mr-2 ">
                                            <House />
                                        </a>
                                        <span>
                                            Plaza X, XY Floor, XYZ Street
                                        </span>
                                    </li>
                                    <li className="flex items-center  space-x-5">
                                        <a href="#" className="mr-2 ">
                                            <Phone />
                                        </a>
                                        <span>+123-456-7890</span>
                                    </li>
                                    <li className="flex items-center space-x-5">
                                        <a href="#" className="mr-2 ">
                                            <Mail />
                                        </a>
                                        <span>yourname@email.com</span>
                                    </li>
                                </ul>
                                <h5 className="py-5 text-slate-500">
                                    OPERATING HOURS
                                </h5>
                                <li className="flex items-center text-left text-white  space-x-5 md:space-x-5 ">
                                    <a href="#" className="mr-2 ">
                                        <Clock />
                                    </a>
                                    <span>
                                        Monday To Friday <br />
                                        <span className="inline-block mt-2">
                                            8:00am to 8:00pm AEDT
                                        </span>
                                    </span>
                                </li>
                            </div>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative flex flex-col justify-center items-center w-full md:w-1/2"
                        >
                            <div className="flex flex-col justify-center items-center md:items-start w-full  rounded-2xl border border-[#2D6A77] bg-[#0B2A40] px-[40px] py-[50px]">
                                <h5 className="text-white text-[18px]">
                                    EMAIL US
                                </h5>
                                <form action="" className="mt-5">
                                    <div className="flex flex-wrap md:!justify-between justify-center items-center flex-row gap-7 text-white">
                                        <input
                                            type="text"
                                            placeholder="Your Name"
                                            className=" focus:!outline-none md:w-2/5 w-[150px] bg-transparent border-transparent !border-b !border-b-[#2D6A77] py-[10px]"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Email Address"
                                            className=" focus:!outline-none md:w-2/5 w-[150px] bg-transparent border-transparent !border-b !border-b-[#2D6A77] py-[10px]"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            className=" focus:!outline-none md:w-2/5 w-[150px] bg-transparent border-transparent !border-b !border-b-[#2D6A77] py-[10px]"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Subject"
                                            className=" focus:!outline-none md:!w-2/5 w-[150px] bg-transparent border-transparent !border-b !border-b-[#2D6A77] py-[10px]"
                                        />
                                    </div>

                                    <div className="pt-8 space-y-5">
                                        <textarea
                                            placeholder="Write your message here"
                                            className="w-full border focus:outline-none border-[#2D6A77] h-[200px] bg-transparent py-[15px] px-[20px] rounded-2xl text-white "
                                        />
                                        <GradientButton
                                            href="#"
                                            className="w-full"
                                        >
                                            Submit
                                        </GradientButton>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Form;
