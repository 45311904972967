import { Check, TimerReset } from "lucide-react";
import translator from "../../../../Components/Extra/Translation/Translate";

const getTimelineData = () => {
    const { translate } = translator();
    return [
        // 2024
        {
            date: translate("ROADMAP", "d1"),
            description: translate("ROADMAP", "desc1"),
            icon: <Check className="text-green-700" size={30} />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
                paddingTop: "10px",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-901 bg-kalyssi-800 border border-kalyssi-200",
        },

        // 2025
        {
            date: translate("ROADMAP", "d2"),
            description: translate("ROADMAP", "desc2"),
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
                paddingTop: "10px",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },

        {
            title: translate("ROADMAP", "t1"),
            description: `${translate("ROADMAP", "desct1")}  
                    ${translate("ROADMAP", "desct1_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },

        {
            title: translate("ROADMAP", "t2"),
            description: `${translate("ROADMAP", "desct2_1")}  
        ${translate("ROADMAP", "desct2_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },

        // 2026
        {
            date: translate("ROADMAP", "d3"),
            description: translate("ROADMAP", "desc3"),
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
                paddingTop: "10px",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },
        {
            title: translate("ROADMAP", "t3"),
            description: `${translate("ROADMAP", "desct3_1")}  
        ${translate("ROADMAP", "desct3_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },

        {
            title: translate("ROADMAP", "t4"),
            description: `${translate("ROADMAP", "desct4_1")}  
        ${translate("ROADMAP", "desct4_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },

        // 2027
        {
            date: translate("ROADMAP", "d4"),
            description: translate("ROADMAP", "desc4"),
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
                paddingTop: "10px",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },
        {
            title: translate("ROADMAP", "t5"),
            description: `${translate("ROADMAP", "desct5_1")}  
        ${translate("ROADMAP", "desct5_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },
        {
            title: translate("ROADMAP", "t6"),
            description: `${translate("ROADMAP", "desct6_1")}  
        ${translate("ROADMAP", "desct6_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },

        //2028
        {
            date: translate("ROADMAP", "d8"),
            description: translate("ROADMAP", "desc8"),
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
                paddingTop: "10px",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },
        {
            title: translate("ROADMAP", "t7"),
            description: `${translate("ROADMAP", "desct7_1")}  
        ${translate("ROADMAP", "desct7_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },
        {
            title: translate("ROADMAP", "t8"),
            description: `${translate("ROADMAP", "desct8_1")}  
        ${translate("ROADMAP", "desct8_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },

        //2029
        {
            date: translate("ROADMAP", "d9"),
            description: translate("ROADMAP", "desc9"),
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
                paddingTop: "10px",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },
        {
            title: translate("ROADMAP", "t9"),
            description: translate("ROADMAP", "desct9"),
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },
        {
            title: translate("ROADMAP", "t10"),
            description: `${translate("ROADMAP", "desct10_1")}  
        ${translate("ROADMAP", "desct10_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },
        {
            title: translate("ROADMAP", "t11"),
            description: `${translate("ROADMAP", "desct11_1")}  
        ${translate("ROADMAP", "desct11_2")}`,
            icon: <TimerReset />,
            contentStyle: {
                background: "#2b2b2b",
                color: "#fff",
            },
            contentArrowStyle: { borderRight: "7px solid #fff" },
            dateClassName: "text-[#FFFFFF] text-bold",
            iconClassName:
                "text-slate-400 bg-kalyssi-800 border border-kalyssi-200",
        },

        // {
        //     date: "2011 - present",
        //     title: "Creative Director",
        //     location: "Miami, FL",
        //     description: "Creative Direction, User Experience, Visual Design, Project Management, Team Leading",
        //     icon: faBriefcase,
        //
        //     contentStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
        //     contentArrowStyle: { borderRight: '7px solid  rgb(33, 150, 243)' },
        //     dateClassName: "text-[#FFFFFF] text-bold",
        //     iconClassName: "text-slate-901 bg-kalyssi-800"
        // },
        // Ajoutez d'autres éléments ici
    ];
};

export default getTimelineData;
