import React from "react";

const Hero: React.FC = () => {
    return (
        <div>
            {/* <!-- ======== 1.2. hero section ======== --> */}
            <section className="flex justify-center items-center mx-8 lg:mx-32 md:pt-32 pt-32 md:pb-8">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="max-w-screen-xl mx-auto w-full text-center pb-8 ">
                        <h1 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text font-bold text-3xl sm:text-3xl lg:!text-5xl leading-tight tracking-tighter">
                            CONTACT
                        </h1>
                        <p className="mt-0 text-[16px] text-slate-200 p-6 md:px-[140px]">
                            Nous sommes ravis de vous entendre et prêts à
                            répondre à toutes vos questions. Que vous ayez
                            besoin d'assistance, de plus d'informations sur nos
                            services, ou que vous souhaitiez simplement partager
                            vos commentaires, n'hésitez pas à nous contacter.
                        </p>
                        <iframe
                            src="https://maps.google.com/maps?width=100%25&height=300&hl=en&q=Commune%20de%20Kaloum%208eme%20Boulevard%20X10eme%20Avenue,%C2%A0Guinea+(Kalyssi%20Mobile%20Payement)&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                            className="flex justify-center items-center w-[100%] h-[400px] rounded-t-3xl mt-5"
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Hero;
