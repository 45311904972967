import Select from "react-select";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import useTheme from "../../../Services/Hooks/useTheme";
import { CurrencySymbol } from "../../../Services/Currency/CurrencyService";

interface SelectInputCurrencyProps {
    id?: string;
    options: CurrencySymbol[];
    components?: any;
    value?: any;
    onChange?: (value: any) => void;
    required?: boolean;
    defaultValue?: any;
    isMulti?: boolean;
}

const SelectInputCurrency = ({
    id,
    options,
    components,
    value,
    onChange,
    required,
    defaultValue,
    isMulti,
}: SelectInputCurrencyProps) => {
    const { mode } = useTheme();

    const formattedOptions = options.map((currency) => ({
        value: currency.abbrev,
        label: `${currency.abbrev} (${currency.symbol})`,
    }));

    return (
        <Select
            id={id}
            options={formattedOptions}
            className="bg-select-view-other"
            components={components}
            value={value}
            onChange={onChange}
            styles={{
                control: (base) =>
                    mode === AppMode.LIGHT
                        ? {
                              ...base,
                              minHeight: 46,
                              borderRadius: "0.5rem",
                              borderColor: "#E2E8F0",
                              background: "transparent",
                              color: "rgb(30 41 59)",
                          }
                        : {
                              ...base,
                              minHeight: 46,
                              borderRadius: "0.5rem",
                              borderColor: "#595c5c",
                              background: "transparent",
                              color: "#FFF",
                          },
                option: (base) =>
                    mode === AppMode.LIGHT
                        ? {
                              ...base,
                              minHeight: 46,
                              background: "#fff",
                              color: "#000",
                              "&:hover": {
                                  color: "black",
                                  background: "#dbeafe",
                              },
                          }
                        : {
                              ...base,
                              minHeight: 46,
                              background: "#0f0f0f",
                              color: "#FFF",
                              "&:hover": {
                                  color: "black",
                                  background: "#dbeafe",
                              },
                          },
            }}
            required={!!required}
            defaultValue={defaultValue}
            isMulti={!!isMulti}
        />
    );
};

export default SelectInputCurrency;
