import { NavLink } from "react-router-dom";
import Dropdown from "../../Components/Extra/Dropdown/Dropdown";
import translator from "../../Components/Extra/Translation/Translate";
import LanguageSwitcher from "../../Layouts/Account/Language/Language";
import ThemeSwitcher from "../../Layouts/Account/ThemeSwticher/ThemeSwitcher";
import RoutesNames from "../../Services/RoutesNames";
import UtilsService from "../../Services/UtilsService";

const HeadAdmin = () => {
    const { translate } = translator();
    return (
        <div className="top-bar mdg-nav-bar mdg-theme-nav">
            <nav
                aria-label="breadcrumb"
                className="-intro-x mr-auto hidden sm:flex"
            >
                <div className="p-4 sm:w-64">
                    <NavLink
                        to="/"
                        className="active mdg-logo mx-auto !mt-[-10px] flex h-10 w-full justify-center text-orange-500 hover:text-yellow-500"
                        aria-current="page"
                    >
                        <img
                            src={UtilsService.getPulicImage(
                                "/dist/logo/Kalyssi-2.png",
                            )}
                            className="h-[50px] w-auto"
                            alt="Kalypay Logo"
                        />
                    </NavLink>
                </div>
            </nav>
            <div className="flex items-center justify-end">
                <div className="intro-x relative mr-3 sm:mr-6">
                    <NavLink
                        to={RoutesNames.Assets}
                        className="btn btn-default-soft w-30 border-gray-500 p-2 text-white"
                    >
                        {translate("Account", "Account")}
                    </NavLink>
                </div>
                <div className="intro-x w-4 h-4 mr-3 sm:mr-6 language">
                    <LanguageSwitcher />
                </div>
                <div className="intro-x dropdown mr-5 flex h-7 w-5 items-center">
                    <ThemeSwitcher />
                </div>
                <div className="intro-x w-8 h-8 profil">
                    <Dropdown />
                </div>
            </div>
        </div>
    );
};

export default HeadAdmin;
