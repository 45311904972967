import React from "react";
import Vesa from "../../../assets/images/bg/about-banner.jpg";
import translator from "../../../Components/Extra/Translation/Translate";
import GradientButton from "../../components/button/GradientButton";

const Visa: React.FC = () => {
    const { translate } = translator();
    return (
        <section className=" flex justify-center items-center -my-[350px] md:-my-[250px] mx-8 mt-32 md:mt-32 lg:mx-32 z-40">
            <div className="max-w-screen-xl mx-auto w-full">
                <div className="rounded-lg" data-aos="zoom-in">
                    <figure>
                        <img
                            src={Vesa}
                            className="w-full object-cover rounded-3xl h-[400px]"
                            alt="visa-img"
                        />
                    </figure>
                    <div
                        className=" relative top-[-370px] md:pt-[70px] text-center md:top-[-420px] md:right-[-300px] mx-50 md:mx-[300px] px-[20px] md:px-[45px] rounded-xl"
                        data-aos="fade-up"
                    >
                        <div className="grid grid-cols-1 gap-2 md:py-0 ">
                            <div className="col-lg-6 col-md-6 md:text-left  md:mr-8">
                                <h3 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text  text-3xl sm:text-3xl lg:!text-5xl font-semibold   leading-tight tracking-tighter pb-4">
                                    {translate("AboutVisa", "title_h3")}
                                </h3>
                                <p className=" text-slate-200 text-base">
                                    {translate("AboutVisa", "p")}
                                </p>
                                <div className="text-center md:text-left pt-4 md:py-10">
                                    <GradientButton href="/ComingSoon">
                                        {translate("AboutVisa", "textButton")}
                                    </GradientButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Visa;
