import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header";
import KalypayFooter from "./KalypayFooter";

const KalypayBase = () => {
    const style: React.CSSProperties = {
        background: "radial-gradient(at -16%, #daa51a -105%, #000 90%)",
        minHeight: "100vh",
        position: "relative",
        zIndex: 1,
    };

    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    useEffect(() => {
        const html = document.querySelector("html");
        if (html) {
            html.style.scrollBehavior = "auto";
            window.scroll({ top: 0 });
            html.style.scrollBehavior = "";
        }
    }, [location.pathname]);

    useEffect(() => {
        AOS.init({
            once: true,
            duration: 800,
            easing: "ease-out-cubic",
        });
    });

    // useEffect(() => {
    //     const html = document.querySelector("html");
    //     if (html) {
    //         html.classList.add(
    //             ...[
    //                 "font-aspekta",
    //                 "antialiased",
    //                 "bg-white",
    //                 "text-slate-800",
    //                 "font-[350]",
    //             ],
    //         );
    //     }

    //     return () => {
    //         if (html) {
    //             html.classList.remove(
    //                 ...[
    //                     "font-aspekta",
    //                     "antialiased",
    //                     "bg-white",
    //                     "text-slate-800",
    //                     "font-[350]",
    //                 ],
    //             );
    //         }
    //     };
    // }, []);

    return (
        <div style={style}>
            <Header />
            <Outlet />
            <KalypayFooter />
        </div>
    );
};

export default KalypayBase;
