import React from "react";
// import GradientButton from "../../component/Bouton/GradientButton";
import logo from "../../../assets/images/Kalyssi-2.png";
// import FooterSlider from "../../Pages/Stick/footerSlide";
import { Link } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import { Facebook, Linkedin } from "lucide-react";
import RoutesNames from "../../../Services/RoutesNames";

const Footer: React.FC = () => {
    const { translate } = translator();
    return (
        <div className="footer-div">
            {/* <!-- ========  footer ========  --> */}
            <footer className="flex justify-center items-center mx-8 lg:mx-32">
                <div className="max-w-screen-xl mx-auto w-full text-center">
                    {/* <h4 className="text-center">SUBSCRIBE OUR NEWSLETTER</h4>
                    <p className="text-center pt-2 pb-3 text-sm lg:text-base p-footer">
                        Get latest News and Updates
                    </p>
                    <form className="flex items-center justify-center my-4 ">
                        <div className="relative flex flex-wrap items-center justify-center">
                            <input
                                type="email"
                                className="w-full pr-8 pl-8 py-2 mb-2 md:pr-[200px] md:w-[600px] md:py-5 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300"
                                placeholder="Enter your Email"
                                required
                            />
                            <div className="md:absolute md:-right-6 right-0 top-0 md:mt-[5px] mt-[4px] md:mr-8">
                                <GradientButton
                                    href="#"
                                    className="bg-slate-600 py-[10px] md:py-5 rounded-full"
                                >
                                    Subscribe
                                </GradientButton>
                            </div>
                        </div>
                    </form> */}

                    <div className="flex flex-col justify-center items-center my-12 pb-4">
                        <figure>
                            <img
                                src={logo}
                                alt="img"
                                className="w-[170px] md:w-[200px] "
                            />
                        </figure>
                        <p className="text-center pt-2 pb-3 text-sm text-white max-w-[250px]">
                            {translate("Footer", "Description")}
                        </p>
                    </div>

                    <ul className="flex justify-center items-center text-white text-sm lg:!text-base my-4 space-x-12 lg:!space-x-14  ">
                        <li className="hover:text-[#ffc107]">
                            <a href="#">{translate("Footer", "Feature")}</a>
                        </li>
                        <li className="hover:text-[#ffc107]">
                            <a href="#">{translate("Footer", "Pricing")}</a>
                        </li>
                        <li className="hover:text-[#ffc107]">
                            <a href="#">{translate("Footer", "About")}</a>
                        </li>
                        <li className="hover:text-[#ffc107]">
                            <a href="#">{translate("Footer", "Faq")}</a>
                        </li>
                    </ul>

                    <hr className="py-4" />

                    <div className="md:grid md:grid-cols-4  justify-center lg:!justify-between md:!space-y-0 space-y-3 items-center text-sm lg:!text-base ">
                        <div className=" flex flex-col justify-center md:!items-start items-center ">
                            <div className="flex md:items-start">
                                <div className="flex gap-2 text-[#212529] text-[20px]">
                                    <a
                                        href="https://web.facebook.com/KalyssiOficial"
                                        target="_blank"
                                    >
                                        <div className="bg-white hover:bg-kalyssi-500 rounded-full cursor-pointer w-12 h-12 flex items-center justify-center">
                                            <Facebook
                                                className="text-black"
                                                size={24}
                                            />
                                        </div>
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/kalyssi/mycompany/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="bg-white hover:bg-kalyssi-500 rounded-full cursor-pointer w-12 h-12 flex items-center justify-center">
                                            <Linkedin
                                                className="text-black"
                                                size={24}
                                            />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4 md:pb-0 col-span-2 text-white md:!justify-center items-center justify-center">
                            <Link
                                to={RoutesNames.MentionLegale}
                                className="hover:text-[#ffc107]"
                            >
                                {translate("Footer", "Terms")}
                            </Link>
                            <Link
                                to={RoutesNames.Condition}
                                className="hover:text-[#ffc107]"
                            >
                                {translate("Footer", "Condition")}
                            </Link>
                            <Link
                                to={RoutesNames.Confidentiality}
                                className="hover:text-[#ffc107]"
                            >
                                {translate("Footer", "Confidentiality")}
                            </Link>
                        </div>
                        <div className="overflow-hidden md:ml-14">
                            {/* <FooterSlider /> */}
                        </div>
                    </div>
                    <hr className="mt-8" />
                    <div className="flex justify-center items-center flex-wrap text-center text-sm lg:!text-base text-white my-4">
                        <p className="mb-2 lg:mb-0">
                            Copyright © 2024 Kalyssi by{" "}
                            <a
                                className="text-kalyssi-500"
                                href="https://kalyssigroup.com"
                                target="_blank"
                            >
                                Kalyssi GROUP
                            </a>
                            . All Right Reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
