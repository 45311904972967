import UtilsService from "../../../Services/UtilsService";

const KalyPayMobileLogo = ({ className }: { className: string }) => {
    return (
        <img
            src={UtilsService.getPulicImage("/dist/image/Logo_Fav_KP.png")}
            className={className}
            alt="Logo Kalypay"
        />
    );
};
const KalypayLogo = ({ className }: { className: string }) => {
    return (
        <img
            src={UtilsService.getPulicImage("/dist/logo/Kalyssi-2.png")}
            className={className}
            alt="Kalypay Logo"
        />
    );
};

export { KalyPayMobileLogo, KalypayLogo };
