import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import GradientButton from "../../components/button/GradientButton";

const About: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.3. about section ========  --> */}
            <section className="flex justify-center items-center mx-8  lg:mx-32">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="grid grid-cols-1 gap-5">
                        <div
                            className=" bg-slate-800 border leading-normal space-y-6 border-kalyssi-200 rounded-xl p-10 hover:bg-slate-600"
                            data-aos="flip-left"
                        >
                            <h3 className="font-bold text-2xl text-gray-300">
                                {translate("ABOUT", "Title")}
                            </h3>
                            <p className="text-base text-[#E4E4E4]">
                                {translate("ABOUT", "p")}
                            </p>
                            <div className="flex items-center justify-start md:justify-start space-x-9">
                                <GradientButton
                                    href="/ComingSoon"
                                    className=" text-[12px] md:text-base"
                                >
                                    {translate("ABOUT", "Learn_more")}
                                </GradientButton>
                                {/* <div className="relative w-30 px-6">
                                    <a
                                        className="relative z-10 block w-16 h-27 rounded-full border border-white overflow-hidden"
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            padding: "18px 20px 18px 28px",
                                        }}
                                        href={ undefined }
                                        onClick={e => e.preventDefault()}
                                    >
                                        <span className="absolute inset-0 flex items-center justify-center">
                                            <span className="w-60 h-60 rounded-full bg-100 animate-pulse"></span>
                                        </span>
                                        <span className="text-white font-bold text-[25px] flex items-center justify-center w-full h-full rounded-full">
                                            <FontAwesomeIcon icon={faPlay} />
                                        </span>
                                    </a>
                                </div>
                                <div className="text-[14px] text-white">
                                    Watch Video
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="p-7.5 py-5 bg-slate-600 border border-kalyssi-200 rounded-xl p-10 md:mt-0 sm:mt-3 mt-3" data-aos="flip-right">
                            <div className="grid grid-cols-2 gap-3 items-center text-gray-300 ">
                                <div className="col-span-1 sm:col-span-1 md:col-span-1 flex flex-col justify-end items-start pr-3">
                                    <div className="flex justify-start items-center mb-3">
                                        <h3 className="font-bold">
                                            <CounterUp delay={10} time={5000}>
                                                38
                                            </CounterUp>
                                        </h3>
                                        <h3 className="ml-1">M+</h3>
                                    </div>
                                    <div>
                                        <p className="text-sm">
                                            Business have already joined us!
                                            Business have already joined us!
                                        </p>
                                    </div>
                                </div>
                                <div className="col-span-1 sm:col-span-1 md:col-span-1 flex flex-col justify-end items-start relative">
                                    <div className="flex justify-start items-center px-6 mb-3">
                                        <h3 className="font-bold">0%</h3>
                                    </div>
                                    <div>
                                        <p className="p-f-s px-6 text-sm">
                                            Zero percent fee to any transaction
                                            Zero percent fee to any transaction
                                        </p>
                                    </div>
                                    <div className="absolute top-0 bottom-0 left-0 w-px bg-gray-400"></div>
                                </div>
                                <div className="col-span-1 sm:col-span-1 md:col-span-1 flex justify-center">
                                    <div className="transform -rotate-90">
                                        <hr className="w-full bg-gray-400"></hr>
                                    </div>
                                </div>
                            </div>
                            <div className="flex mb-3 mt-4 gap-0 justify-start">
                                <img src={aboutCardImg1} alt="about_img1" />
                                <img src={aboutCardImg2} alt="about_img2" />
                                <div className="ps-2 ">
                                    <span className="text-kalyssi-500">
                                        <FontAwesomeIcon icon={faStar} />
                                        <FontAwesomeIcon icon={faStar} />
                                        <FontAwesomeIcon icon={faStar} />
                                        <FontAwesomeIcon icon={faStar} />
                                        <FontAwesomeIcon icon={faStar} />
                                    </span>
                                    <p className="text-gray-300 mt-2">
                                        So many people like this service
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default About;
