import Roadmap from "../Landing/pages/Learn/Roadmap";

const RoutesNames = {
    Home: "/",
    Invest: "/invest",
    Discover: "/discover",
    Login: "/auth/login",
    SilentLogin: "/auth/silent/login",
    Register: "/auth/register",
    SilentRegister: "/auth/silent/register",
    Dashboard: "/dashboard",
    Assets: "/assets",
    Transactions: "/transactions",
    Payment: "/payments",
    Ecosystem: {
        Index: "/ecosystem",
        Perfectnodes: "/ecosystem/perfectnodes",
        Exchange: "/ecosystem/exchange",
        Kalysynthex: "/ecosystem/kalysynthex",
        KankouDao: "/ecosystem/kankou-dao",
        Kalyswap: "/ecosystem/kalyswap",
        FastNProduct: "/ecosystem/fastnproduct",
    },
    Cards: "/cards",
    Customer: "/customer",
    ColdWallet: "/cold-wallet",
    Iban: "/iban",
    Earning: "/earning",
    Lending: "/lending",
    Nodes: "/nodes",
    Trading: "/trading",
    Services: "/services",
    Loan: "/borrow",
    Loaylty: "/loyalty",
    FindAgent: "/find-agent",
    Setting: {
        Index: "/settings",
        General: "/settings/general",
        Security: "/settings/security",
        Referrals: "/settings/referrals",
        Position: "/settings/position",
        Aplications_web: "/settings/application-web",
        Qr_code_merchant: "/settings/qr-code-merchant",
    },
    Partners: "/partners",
    Faq: "/support/to-start/getting-started-with-kalypay",
    CardOrder: "/orders",
    Accounting: {
        Index: "/accounting",
        Balance: "/accounting/balance",
        Fee: "/accounting/free",
    },
    Moderation: "/moderation",
    Point_of_sale: "/point-of-sale",
    Commercial: "/commercial",
    Sales_manager: "/sales-manager",
    // Coldwallet: {
    //     Index: "/cold-wallet",
    // },
    Profil: "profil/:uid",
    PaymentKp: "/payment-checkout",
    DepositVirementFA: "/virement",
    Support: "/support",
    Maintenance: "/maintenance",
    Branding: "/branding",
    MentionLegale: "/mention-legale",
    Condition: "/condition",
    CGV: "/cgv",
    Confidentiality: "/confidentiality",
    LegalWarning: "/legal-warning",
    KycAml: "/kyc-aml",
    OurFee: "/our-fee",
    Admin: {
        Index: "/admin",
        Dashboard: "/admin/dashboard",
        User: "/admin/user",
        Transactions: "/admin/all-transactions",
        SettingPayment: "/admin/setting/payment",
    },
    Business_dev: "/business-dev",
    MailModeration: "/mail-moderation",
    BloquedUsers: "/blocked-users",
    TransactionModeration: "/transaction-moderation",
    Borrow: "/borrow",
    AmlCheck: "/aml-check",
    AmlCheckId: "/aml-check/:id",
    About: "/about",
    Feature: "/feature",
    Roadmap: "/roadmap",
    Contact: "/contact",
};

export default RoutesNames;
