import React from "react";
import ladymobile from "../../../assets/images/index/lady-mobile.png";
import servicebghero from "../../../assets/images/bg/services-bg.png";
import { ChevronRight, Facebook, Twitter, Youtube } from "lucide-react";
import GradientButton from "../../components/button/GradientButton";
import translator from "../../../Components/Extra/Translation/Translate";
import CounterUp from "../../components/Counter/counterUp";

interface IconPinterestProps {
    size?: string | number;
    className?: string;
}

const Pinterest: React.FC<IconPinterestProps> = ({
    size = "1em",
    className = "",
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            className={className}
            fill="currentColor"
        >
            <path d="M9.04 21.54c.96.29 1.93.46 2.96.46a10 10 0 0 0 10-10A10 10 0 0 0 12 2A10 10 0 0 0 2 12c0 4.25 2.67 7.9 6.44 9.34c-.09-.78-.18-2.07 0-2.96l1.15-4.94s-.29-.58-.29-1.5c0-1.38.86-2.41 1.84-2.41c.86 0 1.26.63 1.26 1.44c0 .86-.57 2.09-.86 3.27c-.17.98.52 1.84 1.52 1.84c1.78 0 3.16-1.9 3.16-4.58c0-2.4-1.72-4.04-4.19-4.04c-2.82 0-4.48 2.1-4.48 4.31c0 .86.28 1.73.74 2.3c.09.06.09.14.06.29l-.29 1.09c0 .17-.11.23-.28.11c-1.28-.56-2.02-2.38-2.02-3.85c0-3.16 2.24-6.03 6.56-6.03c3.44 0 6.12 2.47 6.12 5.75c0 3.44-2.13 6.2-5.18 6.2c-.97 0-1.92-.52-2.26-1.13l-.67 2.37c-.23.86-.86 2.01-1.29 2.7z" />
        </svg>
    );
};

const Service: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.7. services section ========  --> */}
            <section className="flex justify-center items-center mx-8 lg:!mx-32 md:py-20 md:pt-32 pb-52 mb-8">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="flex flex-col md:flex-row justify-center items-center gap-5 sm:gap-4 md:gap-0 text-center md:text-left">
                        <div
                            data-aos="fade-up"
                            className="flex flex-col md:!text-left justify-center items-center md:items-start w-full md:w-1/2"
                        >
                            <h2 className="text-3xl sm:text-3xl lg:!text-5xl font-semibold leading-tight tracking-tighter">
                                {translate("SERVICE", "Title")}
                            </h2>
                            <p className="text-gray-300 my-6 text-base leading-normal font-poppins">
                                {translate("SERVICE", "Description")}
                            </p>
                            <div className="flex md:!flex-row flex-col justify-center gap-4 md:gap-3 sm:gap-2 py-[28px] text-slate-500">
                                <div className="bg-kalyssi-800 border border-kalyssi-200 rounded-xl px-4 py-4">
                                    <h5 className="text-white text-xl font-semibold  mb-2">
                                        {translate(
                                            "SERVICE",
                                            "Transaction_Title",
                                        )}
                                    </h5>
                                    <p className="text-base text-white  leading-normal">
                                        {translate(
                                            "SERVICE",
                                            "Transaction_Text",
                                        )}
                                    </p>
                                </div>
                                <div className=" bg-kalyssi-800 border border-kalyssi-200 rounded-xl px-4 py-4">
                                    <h5 className="text-white text-xl font-semibold mb-2">
                                        {translate(
                                            "SERVICE",
                                            "No_Hidden_Fees_Title",
                                        )}
                                    </h5>
                                    <p className="text-base text-white leading-normal">
                                        {translate(
                                            "SERVICE",
                                            "No_Hidden_Fees_Text",
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="flex md:!justify-start mr-auto justify-center items-center gap-4 md:gap-4 sm:gap-2">
                                <GradientButton
                                    href="/ComingSoon"
                                    className="md:!text-base text-xs"
                                >
                                    {translate("SERVICE", "Learn_More_Button")}
                                </GradientButton>
                                <div className="flex items-center ml-4 text-[#DB9B1E] ">
                                    <a
                                        href={undefined}
                                        className="md:!text-base text-sm hover:text-[#db8f1e]"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {translate(
                                            "SERVICE",
                                            "SignUp_Now_Text",
                                        )}
                                    </a>
                                    <ChevronRight />
                                </div>
                            </div>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative w-full md:w-1/2 sm:mt-5 md:-top-[70px]"
                        >
                            {/* <!-- Background Container with specified size and background size adjustment --> */}
                            <div
                                className="absolute inset-0 flex justify-center items-center bg-cover bg-center md:w-[120%] w-[100%]"
                                style={{
                                    backgroundImage: `url(${servicebghero})`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                }}
                            ></div>

                            {/* <!-- Image Container --> */}
                            <div className="relative flex justify-center items-center h-full py-5">
                                <img
                                    src={ladymobile}
                                    alt="hero_img1"
                                    className="relative w-1/2 sm:w-1/4 md:w-2/3 lg:w-1/2 mx-auto right-2 top-4"
                                />
                            </div>
                            <div className="absolute inset-0 flex justify-center items-center mt-[360px] md:mt-[450px] sm:ml-8 lg:mr-[90px]">
                                <div className="bg-slate-700 px-[24px] py-[30px] w-[330px] rounded-2xl shadow-lg">
                                    <div className="flex flex-col  justify-start">
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-start justify-start space-x-2">
                                                <span>
                                                    <Youtube
                                                        size={45}
                                                        className="bg-red-600 rounded-full p-2 cursor-pointer text-[#E3ECE7] "
                                                    />
                                                </span>
                                                <div className="relative text-left ">
                                                    <h6 className="font-bold text-base text-gray-200">
                                                        Youtube Premium
                                                    </h6>
                                                    <p className="text-sm text-gray-400">
                                                        9 June 2023
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-center text-pink-600 space-x-1">
                                                <p>-$</p>
                                                <p className="count font-bold">
                                                    {" "}
                                                    <CounterUp
                                                        delay={10}
                                                        time={2000}
                                                    >
                                                        3.00
                                                    </CounterUp>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center  py-4">
                                            <div className="flex items-center space-x-2">
                                                <Facebook
                                                    size={45}
                                                    className="bg-blue-700 rounded-full p-2 cursor-pointer text-[#E3ECE7]"
                                                />

                                                <div className="relative text-left">
                                                    <h6 className="font-bold text-base text-gray-200">
                                                        Facebook Ads
                                                    </h6>
                                                    <p className="text-sm text-gray-400">
                                                        5 June 2023
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-center text-green-600 space-x-1">
                                                <p>+$</p>
                                                <p className="count font-bold">
                                                    <CounterUp
                                                        delay={10}
                                                        time={2000}
                                                    >
                                                        21.00
                                                    </CounterUp>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center pb-4">
                                            <div className="flex items-center space-x-2">
                                                <Pinterest
                                                    size={45}
                                                    className="bg-red-600 rounded-full p-2 cursor-pointer text-[#E3ECE7] "
                                                />
                                                <div className="relative text-left">
                                                    <h6 className="font-bold text-base text-gray-200">
                                                        Pinterest
                                                    </h6>
                                                    <p className="text-sm text-gray-400">
                                                        2 June 2023
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-center text-pink-600 space-x-1">
                                                <p>-$</p>
                                                <p className="count font-bold">
                                                    <CounterUp
                                                        delay={10}
                                                        time={2000}
                                                    >
                                                        14.00
                                                    </CounterUp>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center space-x-2">
                                                <span>
                                                    <Twitter
                                                        size={45}
                                                        className="bg-blue-400 rounded-full p-2 cursor-pointer text-[#E3ECE7]"
                                                    />
                                                </span>
                                                <div className="relative text-left">
                                                    <h6 className="font-bold text-base text-gray-200">
                                                        Twitter
                                                    </h6>
                                                    <p className="text-sm text-gray-400">
                                                        1 June 2023
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-center text-green-600 space-x-1">
                                                <p>+$</p>
                                                <p className="count font-bold">
                                                    <CounterUp
                                                        delay={10}
                                                        time={2000}
                                                    >
                                                        51.00
                                                    </CounterUp>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Service;
