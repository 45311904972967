import { NavLink, useLocation } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import DropdownLanguage from "../../Front/Components/Theme/DropdownLanguage";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import RoutesNames from "../../Services/RoutesNames";
import UtilsService from "../../Services/UtilsService";
import MenuItem, { MenuItemProps } from "../components/nav/menu-item";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { User2, SquareMenu, SquareX } from "lucide-react";
import GradientButton from "../components/button/GradientButton";

const Header = () => {
    const [sideNavWidthSide, setSideNavWidthSide] = useState("0px");
    const [icon, setIcon] = useState("menu");
    const { isAuthenticated } = useAuthentication();
    const { translate } = translator();
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    const openNavSide = () => {
        setSideNavWidthSide("100vw");
        setIcon("close");
        document.body.style.overflow = "hidden";
    };

    const closeNavSide = useCallback(() => {
        setSideNavWidthSide("0px");
        setIcon("menu");
        document.body.style.overflow = "";
    }, []);

    const menuItems: MenuItemProps[] = useMemo(() => {
        return [
            {
                path: RoutesNames.Home,
                label: translate("HEADER_LANDING", "Home"),
            },
            {
                path: RoutesNames.About,
                label: translate("HEADER_LANDING", "About"),
            },
            {
                path: RoutesNames.Feature,
                label: translate("HEADER_LANDING", "Feature"),
            },
            {
                path: "#",
                label: translate("HEADER_LANDING", "Product"),
                children: [
                    { path: "/ComingSoon", label: "IBAN" },
                    { path: "/ComingSoon", label: "Transfert" },
                    { path: "/ComingSoon", label: "Money Contrôle" },
                    { path: "/ComingSoon", label: "Carte visa physique" },
                    { path: "/ComingSoon", label: "Carte visa virtuelle" },
                    { path: "/ComingSoon", label: "Coffre" },
                    { path: "/ComingSoon", label: "Prêt" },
                    { path: "/ComingSoon", label: "Business" },
                ],
            },
            {
                path: "#",
                label: translate("HEADER_LANDING", "Learn"),
                children: [
                    { path: RoutesNames.Contact, label: "Contact" },
                    { path: "/pricing", label: "Prix" },
                    { path: "/faq", label: "FAQ" },
                    { path: RoutesNames.Roadmap, label: "Roadmap" },
                ],
            },
            {
                path: "#",
                label: translate("HEADER_LANDING", "Agent"),
            },
        ];
    }, [translate]);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const headerBackgroundClass = useMemo(() => {
        if (!isScrolled) {
            return "bg-transparent";
        }
        return "bg-slate-800 transition-colors duration-300";
    }, [isScrolled]);

    useEffect(() => {
        closeNavSide();
    }, [closeNavSide, location.pathname]);

    return (
        <div className={`fixed top-0 w-full z-50 ${headerBackgroundClass}`}>
            <nav className="flex flex-row justify-between mx-4 lg:!mx-32 py-3 text-[#f8fafc]">
                <NavLink to="/" className="flex items-center" aria-label="Home">
                    <img
                        src={UtilsService.getPulicImage(
                            "/dist/logo/Kalyssi-2.png",
                        )}
                        className="md:!w-52 !block w-40 md:-ml-0 -ml-4"
                        alt="Kalypay Logo"
                    />
                </NavLink>
                <div className="flex flex-row items-center gap-4">
                    <div className="hidden lg:!flex items-center text-base text-white space-x-7">
                        {menuItems.map((menu, index) => (
                            <MenuItem key={`menu-${index}`} {...menu} />
                        ))}
                    </div>
                    <div className="flex items-center space-x-4">
                        {isAuthenticated ? (
                            <div className="intro-x relative mr-2 ">
                                <GradientButton
                                    className="text-base  items-center hidden md:!inline-flex"
                                    href={RoutesNames.Assets}
                                >
                                    <div className="hidden md:!flex flex-row items-center">
                                        {translate("GENERAL", "MY_ACCOUNT")}
                                        <span className="tracking-normal text-[#EEA913] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2 ">
                                            <svg
                                                className="fill-current"
                                                width="12"
                                                height="10"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                            </svg>
                                        </span>
                                    </div>
                                </GradientButton>
                                <GradientButton
                                    href={RoutesNames.Dashboard}
                                    className="flex md:!hidden  text-base items-center justify-center "
                                >
                                    <User2 />
                                </GradientButton>
                            </div>
                        ) : (
                            <>
                                <GradientButton
                                    className="hidden md:!inline-flex items-center text-base font-semibold"
                                    href={RoutesNames.Register}
                                >
                                    {translate("GENERAL", "SIGNUP")}
                                </GradientButton>
                                <GradientButton
                                    className="hidden md:!inline-flex items-center text-base font-semibold bg-kalyssi-200"
                                    href={RoutesNames.Login}
                                >
                                    {translate("GENERAL", "SIGNIN")}
                                </GradientButton>
                            </>
                        )}

                        {/* <ThemeToggle /> */}
                        <div className="z-40">
                            <DropdownLanguage align="center" />
                        </div>
                        <button
                            type="button"
                            onClick={
                                sideNavWidthSide === "0px"
                                    ? openNavSide
                                    : closeNavSide
                            }
                            aria-controls="navbarSupportedContent"
                            aria-label="Toggle navigation"
                            className="lg:!hidden block ml-3"
                        >
                            {icon === "menu" ? (
                                <SquareMenu
                                    size={45}
                                    className=" text-kalyssi-100"
                                />
                            ) : (
                                <SquareX
                                    size={45}
                                    className=" text-kalyssi-100"
                                />
                            )}
                        </button>
                    </div>
                </div>
            </nav>

            {/* <!-- Sidebar Left navigation --> */}
            <aside>
                <div
                    className={`lg:hidden fixed z-20 top-[70px] left-0 h-full w-0
        transition-all duration-500 ease-in-out backdrop-blur-md
        overflow-hidden ${
            sideNavWidthSide !== "0px"
                ? "bg-[radial-gradient(at_-16%,_#daa51a_-105%,_#000_90%)]"
                : "bg-transparent"
        }`}
                    style={{
                        width: sideNavWidthSide,
                    }}
                >
                    <ul className="flex flex-col items-start space-y-2 my-10 text-white">
                        {menuItems.map((menu, index) => (
                            <Fragment key={`m-menu-${index}`}>
                                <MenuItem {...menu} />
                                <hr className="w-full border-white/15 my-1" />
                            </Fragment>
                        ))}
                    </ul>

                    <div className="flex flex-row justify-center text-base items-center gap-5">
                        {!isAuthenticated && (
                            <>
                                <GradientButton
                                    className="flex items-center text-sm"
                                    href={RoutesNames.Register}
                                >
                                    {translate("GENERAL", "SIGNUP")}
                                </GradientButton>
                                <GradientButton
                                    className=" flex items-center text-sm bg-kalypay-200 "
                                    href={RoutesNames.Login}
                                >
                                    {translate("GENERAL", "SIGNIN")}
                                </GradientButton>
                            </>
                        )}

                        {isAuthenticated && (
                            <>
                                <GradientButton
                                    className="flex items-center text-sm bg-kalypay-200 "
                                    href={RoutesNames.Assets}
                                >
                                    {translate("GENERAL", "MY_ACCOUNT")}
                                </GradientButton>
                            </>
                        )}
                    </div>
                </div>
            </aside>
        </div>
    );
};

export default Header;
