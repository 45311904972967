import "animate.css";
import translator from "../../../Components/Extra/Translation/Translate";
import { useAppSelector } from "../../../globalState/hook";
import AuthComponent from "./AuthComponent";
import TwoFALogin from "./TwoFALogin";
const Login = () => {
    const { translate } = translator();
    const { need2fa } = useAppSelector((store) => store.auth);
    return (
        <div className="login h-screen !p-0">
            <div className="p-5">
                <div className="block grid-cols-2 gap-4 xl:grid">
                    <div className="hidden min-h-screen flex-col xl:flex">
                        <div className="m-auto">
                            <div className="-intro-x mt-10 text-4xl font-medium leading-tight text-white">
                                KALYSSI <br />
                                {translate("SignIn", "New_Text")}
                                <br />
                                {translate("SignIn", "New_Text_2")}
                            </div>
                        </div>
                    </div>
                    <div className="my-10 sm:w-full flex !sm:h-auto sm:pt-[50px] justify-center !h-screen xl:my-0 xl:h-auto overflow-hegith py-[70px] sm:pb-[70px] md:py-[70px] lg:py-[70px] xl:py-[70px] 2xl:py-8 mdg-register-ctn ">
                        {need2fa ? <TwoFALogin /> : <AuthComponent />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
