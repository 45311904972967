import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";

const MentionLegale: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            <section className="flex justify-center items-center mx-8 lg:mx-32 md:pt-32 pt-24 pb-15">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="max-w-screen-xl mx-auto w-full text-center py-8 text-slate-888 ">
                        <h1 className="text-transparent bg-gradient-to-r from-yellow-600 to-green-200 bg-clip-text font-bold text-5xl sm:text-3xl lg:text-6xl leading-tight tracking-tighter">
                            {translate("Mention", "Title")}
                        </h1>
                        <div>
                            <div className="bg-[#e5e7eb] text-left md:px-[140px] px-[20px] py-[80px] mt-[70px] rounded-tl-[120px]  ">
                                <span>
                                    <h5 className="text-[14px]">
                                        1. {translate("Mention", "Title1")}
                                    </h5>
                                    <p className="text-[14px]">
                                        {translate("Mention", "Title1_p1a")}{" "}
                                        <a
                                            href="https://kalyssi.com"
                                            className="text-[#EEA913]"
                                        >
                                            https://kalyssi.com
                                        </a>{" "}
                                        {translate("Mention", "Title1_p1b")}
                                    </p>
                                </span>
                                <span>
                                    <h5 className="text-[14px] mt-[30px]">
                                        {translate("Mention", "Owner")} :
                                        KALYSSI
                                    </h5>
                                </span>
                                <span className="flex items-center space-x-2">
                                    <h5 className="text-[14px]">
                                        {translate("Mention", "Register")} :
                                    </h5>
                                    <p>034/R.E.T/2018</p>
                                </span>

                                <span className="flex items-center space-x-2 ">
                                    <h5 className="text-[14px]">
                                        {translate("Mention", "Contact")} :
                                    </h5>
                                    <p>contact@kalyssi.com</p>
                                </span>

                                <div>
                                    <h5 className="text-[14px]">
                                        {translate("Mention", "Adress")} :
                                    </h5>
                                    <div>
                                        <p>Commune de Kaloum ,</p>
                                        <p>8eme Boulevard X10eme Avenue,</p>
                                        <p>Guinea</p>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <div className="flex items-center mt-[30px]">
                                        <h5 className="text-[14px]">
                                            {translate("Mention", "Hosting")} :
                                        </h5>
                                        <p className="ml-1">Planet Hoster</p>
                                    </div>
                                    <div>
                                        <p>4416 Louis-B.-Mayer</p>
                                        <p>Laval, Québec</p>
                                        <p>Canada</p>
                                        <p> H7P 0G1</p>
                                    </div>
                                </div>

                                <span>
                                    <h5 className="text-[14px]">
                                        2. {translate("Mention", "Title2")}
                                    </h5>
                                    <p>{translate("Mention", "Title2_p")}</p>
                                </span>

                                <span>
                                    <h5 className="text-[14px] mt-[30px]">
                                        3. {translate("Mention", "Title3")}
                                    </h5>
                                    <p>{translate("Mention", "Title3_p")}</p>
                                </span>
                                <div className="flex flex-col mt-[30px]">
                                    <h5 className="text-[14px]">
                                        4. {translate("Mention", "Title4")}
                                    </h5>
                                    <span className="flex flex-col">
                                        <p>
                                            {translate("Mention", "Title4_p1")}
                                        </p>
                                        <p>
                                            {translate("Mention", "Title4_p2")}
                                        </p>
                                    </span>
                                </div>
                                <span>
                                    <h5 className="text-[14px] mt-[30px]">
                                        <span>
                                            <h5 className="text-[14px] mt-[30px]">
                                                5.{" "}
                                                {translate("Mention", "Title5")}
                                            </h5>
                                            <p>
                                                {translate(
                                                    "Mention",
                                                    "Title5_p",
                                                )}
                                            </p>
                                        </span>
                                    </h5>
                                </span>

                                <span>
                                    <h5 className="text-[14px] mt-[30px]">
                                        6. {translate("Mention", "Title6")}
                                    </h5>
                                    <p>{translate("Mention", "Title6_p")}</p>
                                </span>

                                <span>
                                    <h5 className="text-[14px] mt-[30px]">
                                        7. {translate("Mention", "Title7")}
                                    </h5>
                                    <p>
                                        {translate("Mention", "Title7_p")}
                                        <span className="font-bold">
                                            {" "}
                                            KALYSSI.
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MentionLegale;
