import React from "react";
import hero from "../../../assets/images/index/hero.png";
import herowatch from "../../../assets/images/index/hero_watch.png";
import herostar from "../../../assets/images/icon/hero_star.png";
import bghero from "../../../assets/images/bg/hero_bg.png";
import GradientButton from "../../components/button/GradientButton";
import translator from "../../../Components/Extra/Translation/Translate";

const Hero: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.2. hero section ======== --> */}
            <section className="flex justify-center items-center mx-8 lg:!mx-32 md:!pt-0 pt-32 min-h-screen">
                <div className="max-w-screen-xl mx-auto w-full ">
                    <div className="flex flex-col md:flex-row justify-center items-center gap-5 sm:gap-4 md:gap-0 text-center md:text-left">
                        <div
                            data-aos="fade-up"
                            className="flex flex-col md:!text-left text-center justify-center items-center md:!items-start w-full md:w-1/2"
                        >
                            <h2 className="text-3xl sm:text-3xl lg:!text-5xl font-semibold  leading-tight tracking-tighter">
                                {translate("HERO", "STRENGTHEN_YOUR_FINANCES")}
                            </h2>
                            <p className="text-gray-300 my-6 text-base leading-normal">
                                {translate("HERO", "p")}
                            </p>
                            <GradientButton href="/ComingSoon">
                                {" "}
                                {translate("HERO", "Get_started")}
                            </GradientButton>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full md:w-1/2 sm:mt-5 bg-cover bg-center md:bg-left py-[78px]"
                            style={{ backgroundImage: `url(${bghero})` }}
                        >
                            <div className="flex justify-center items-center mx-5">
                                <img
                                    src={herostar}
                                    alt="hero_icon"
                                    className="absolute md:-left-8 left-0 mb-[450px]"
                                />
                                <img
                                    src={hero}
                                    alt="hero_img1"
                                    className="px-2 animate-moveUpDown duration-[3.5s] ease-in-out infinite opacity-98"
                                />
                            </div>
                            <img
                                src={herowatch}
                                alt="hero_img2"
                                className="absolute bottom-20 left-7"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Hero;
