import React from "react";
import world from "../../../assets/images/about/round-word.jpg";
import translator from "../../../Components/Extra/Translation/Translate";
import CounterUp from "../../components/Counter/counterUp";

const World: React.FC = () => {
    const { translate } = translator();
    return (
        <div>
            <section className="flex justify-center items-center mx-8 lg:mx-32 md:my-32 mb-5">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="flex flex-col md:flex-row justify-center items-stretch gap-5 sm:gap-4 md:gap-0 text-center md:!text-left">
                        <div
                            data-aos="fade-up"
                            className="flex flex-col justify-center items-center md:!items-start w-full md:w-1/2"
                        >
                            <h2 className="text-3xl sm:text-3xl lg:!text-5xl font-semibold leading-tight tracking-tighter">
                                {translate("AboutWorld", "title_h2")}
                            </h2>
                            <p className="text-gray-300 my-6 text-base leading-normal md:mr-4 ">
                                {translate("AboutWorld", "p1")}
                            </p>
                            <div className="flex flex-col items-center md:!items-start text-base">
                                <div className="flex flex-wrap justify-center md:justify-start text-center gap-4 md:gap-3 sm:gap-2 py-4 text-[#E4E4E4]">
                                    <div className="md:!w-[250px] h-[150px] w-full sm:w-[250px] bg-slate-800 border border-kalyssi-500 rounded-xl px-4 md:!px-8 py-8">
                                        <h3 className="mb-2 font-semibold text-4xl">
                                            <CounterUp delay={10} time={5000}>
                                                100
                                            </CounterUp>
                                            K+
                                        </h3>
                                        <p>
                                            {translate("AboutWorld", "div_p1")}
                                        </p>
                                    </div>
                                    <div className="md:!w-[250px] h-[150px] w-full sm:w-[250px] bg-slate-600 border border-kalyssi-500 rounded-xl px-4 md:px-8 py-8">
                                        <h3 className="mb-2 font-semibold text-4xl">
                                            <CounterUp delay={10} time={5000}>
                                                400
                                            </CounterUp>
                                            K+
                                        </h3>
                                        <p>
                                            {translate("AboutWorld", "div_p2")}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-wrap justify-center items-center md:!justify-start text-center gap-4 md:gap-3 sm:gap-2 text-[#E4E4E4]">
                                    <div className="md:!w-[250px] h-[150px] w-full sm:w-[250px] bg-slate-600 border border-kalyssi-500 rounded-xl px-4 md:!pointer-events-auto py-8">
                                        <h3 className="mb-2 font-semibold text-4xl">
                                            <CounterUp delay={10} time={5000}>
                                                90
                                            </CounterUp>
                                            %
                                        </h3>
                                        <p>
                                            {translate("AboutWorld", "div_p3")}
                                        </p>
                                    </div>
                                    <div className="md:!w-[250px] h-[150px] w-full sm:w-[250px] bg-slate-800 border border-kalyssi-500 rounded-xl px-4 md:px-8 py-8">
                                        <h3 className="mb-2 font-semibold text-4xl">
                                            <CounterUp delay={10} time={5000}>
                                                200
                                            </CounterUp>
                                            K+
                                        </h3>
                                        <p>
                                            {translate("AboutWorld", "div_p4")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full md:w-1/2"
                        >
                            <div className="relative flex justify-center items-center w-full">
                                <img
                                    src={world}
                                    alt="hero_img1"
                                    className="relative sm:w-full md:w-[110%] md:top-1 md:h-[120%] mx-auto opacity-90"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default World;
